import React from "react";
import "./TextSlider.scss";

export interface TextList {
  text: string;
  isHeading?: boolean;
}

interface TextSliderProps {
  list: TextList[];
  className?: string;
}

const TextSlider = (props: TextSliderProps) => {
  const { list, className } = props;

  return (
    <div className={`text-slider  ${className}`}>
      <span className={`text-slider__alternative has-${list.length}-words`}>
        {list.map((item, i) => (
          <div key={`text-slide-${i}`}>
            {item.isHeading ? <h1>{item.text}</h1> : <span>{item.text}</span>}
          </div>
        ))}
      </span>
    </div>
  );
};

export default TextSlider;
